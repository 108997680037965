<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="formRef" :model="formState" @finish="onSearch">
				<a-row>
					<a-form-item label="标题" name="title" class="ui-form__item">
						<a-input v-model:value="formState.title" placeholder="请输入标题"></a-input>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled" class="ui-form__item">
						<a-select v-model:value="formState.isDisabled" placeholder="请选择" allowClear style="width: 190px;">
							<a-select-option :value="0">已启用</a-select-option>
							<a-select-option :value="1">已禁用</a-select-option>
						</a-select>
					</a-form-item>
				</a-row>
				<a-row>
					<a-col :span="16">
						<a-button v-permission="['operation_copyWriting_reason_add']" type="primary" @click="onAdd">新增</a-button>
					</a-col>
					<a-col :span="8" style="text-align: right;">
						<a-button type="primary" html-type="submit">搜索</a-button>
						<a-button style="margin-left: 10px;" @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			<cTable ref="cTable" :columns="columns" :isPage="true" :searchData="searchData" :isRequest="true" :requestFun="getRefundReasonList">
				<template #bodyCell="{column, record}">
					<template v-if="column.key === 'isDisabled'">
						{{ record.isDisabled ? '已禁用' : '已启用' }}
					</template>
					<template v-if="column.key === 'action'">
						<a-dropdown :trigger="['click', 'hover']">
						  <a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
						  </a-button>
						  <template #overlay>
						    <a-menu>
						      <div v-permission="['operation_copyWriting_reason_edit']" @click="onEdit(record)">
						        <a-menu-item>
						          编辑
						        </a-menu-item>
						      </div>
						      <div v-permission="['operation_copyWriting_reason_delete']" @click="onDelete(record)">
						        <a-menu-item>
						          删除
						        </a-menu-item>
						      </div>
						    </a-menu>
						  </template>
						</a-dropdown>
					</template>
				</template>
			</cTable>
		</a-spin>
		
		<a-modal v-model:visible="showModal" :title="modelRef.id ? '编辑' : '新增'"  width="750px" @cancel="onCancel" @ok="onSubmit">
			<a-spin :spinning="loading">
				<a-form ref="formModelRef" :model="modelRef" :labelCol="{span: 5}" :wrapperCol="{span: 16}">
					<a-form-item label="标题" name="title" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.title" placeholder="请输入标题"></a-input>
					</a-form-item>
					
					<a-form-item label="理由" name="reason" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.reason" placeholder="请输入理由"></a-input>
					</a-form-item>
					
					<a-form-item label="状态" name="isDisabled" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-radio-group v-model:value="modelRef.isDisabled">
							<a-radio :value="0">启用</a-radio>
							<a-radio :value="1">禁用</a-radio>
						</a-radio-group>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {Icon} from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import { getRefundReasonList, getRefundReasonDetail, saveRefundReason, updateRefundReason, deleteRefundReason } from '@/service/modules/operation.js';
	export default {
		components: {
			Icon,
			cTable
		},
		data() {
			return {
				loading: false,
				formState: {},
				searchData: {},
				columns: [{
					title: '标题',
					dataIndex: 'title'
				}, {
					title: '理由',
					dataIndex: 'reason'
				}, {
					title: '状态',
					key: 'isDisabled'
				}, {
					title: '操作',
					key: 'action',
					fixed:'right',
					width: 100
				}],
				showModal: false,
				modelRef: {}
			}
		},
		mounted() {
			this.onSearch();
		},
		methods: {
			getRefundReasonList: getRefundReasonList,
			reset() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			onSearch(){
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			getData(isRef) {
				if (isRef) {
					// 重置分页刷新
					this.$refs.cTable.toQuery();
				} else {
					this.$refs.cTable.refQuery();
				}
			},
			onSubmit() {
				this.$refs.formModelRef.validate().then(async ()=> {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					this.loading = true;
					let ret;
					if (postData.id) {
						ret = await updateRefundReason(postData);
					} else {
						ret = await saveRefundReason(postData);
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功')
						this.onCancel();
						this.getData(true);
					}
				})
			},
			onCancel() {
				this.$refs.formModelRef.resetFields();
				this.showModal = false;
			},
			onAdd() {
				this.modelRef = {
					isDisabled: 0
				}
				this.showModal = true;
			},
			onEdit(item) {
				this.modelRef = {...item};
				this.showModal = true;
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async()=> {
						this.loading = true;
						let ret = await deleteRefundReason({
							id: item.id
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功')
							this.getData();
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>